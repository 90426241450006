export const checkMobile = (value) => {
  return /^1(3|4|5|6|7|8|9)\d{9}$/.test(value)
}

export const checkCode = (value) => {
  return /^\d{4}$/.test(value)
}

export const checkEmail = (value) => {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(value)
}
export const checkPassword = (value) => {
  return /^([0]|[1-9][0-9])\d{4,6}$/.test(value)
}
export const checkchangePwd = (value) => {
  return /^\d{8,16}$/.test(value)
}
export const checkSmallPwd = value => {
  return /^\d{4,6}$/.test(value)
}